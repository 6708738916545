/* Chrome, Safari and Opera */
.hourly
{
    display: grid;
    grid-template-columns: repeat(18, calc(22% - 8%));
    grid-template-rows: minmax(100px, 1fr);
    overflow: scroll;
}

@media all and (max-width: 480px) {
    .hourly
    {
        display: grid;
        grid-template-columns: repeat(18, calc(35% - 8.5%));
        grid-template-rows: minmax(100px, 1fr);
        overflow: scroll;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
  
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}